import React from 'react';
const SvgLogo = () => {
    return (
        <div id="svg_logo_div">
					<div className="img_area fade-in-1s">
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="382" height="126"
								 viewBox="0 0 382 126">
							<polygon
								points="35.3 26 26.2 26 26.2 0 0 0 0 73.7 26.2 73.7 26.2 47.4 35.3 47.4 35.3 73.7 61.5 73.7 61.5 0 35.3 0 35.3 26"
								className="svg-elem-1" />
							<polygon
								points="66.2 73.7 112.9 73.7 112.9 53.2 92.4 53.2 92.4 47.2 106.6 47.2 106.6 26.6 92.4 26.6 92.4 20.6 112.9 20.6 112.9 0 66.2 0 66.2 73.7"
								className="svg-elem-2" />
							<polygon
								points="167.7 0 132.2 0 113.1 73.7 139.4 73.7 142.5 61.7 157.5 61.7 160.6 73.7 186.8 73.7 167.7 0"
								className="svg-elem-3" />
							<polygon points="220.4 73.7 239.1 0 212.9 0 208 19.5 203.1 0 176.9 0 195.6 73.7 220.4 73.7"
											 className="svg-elem-4" />
							<rect x="240.5" width="26.2" height="73.7" className="svg-elem-5" />
							<polygon
								points="271.5 73.7 318.2 73.7 318.2 53.2 297.7 53.2 297.7 47.2 312 47.2 312 26.6 297.7 26.6 297.7 20.6 318.2 20.6 318.2 0 271.5 0 271.5 73.7"
								className="svg-elem-6" />
							<path
								d="M381.9,72.2c-.5-7-1.7-13.4-3.4-19.2-1.6-5.4-3.8-10.3-6.4-14.5,1.7-1.8,3.1-4,4-6.5,1.1-2.8,1.6-6,1.6-9.4s-.8-7.8-2.3-11.2c-1.6-3.5-4-6.3-7.1-8.4-3.1-2.1-6.8-3.1-10.8-3.1h-36.3v73.7h26.2v-26.1c1.9.5,3.4,2.2,4.5,5.1,1.5,3.9,2.5,10.5,3,19.7v1.3h27.2v-1.5Z"
								className="svg-elem-7" />

							<polygon
								points="125.7 100.4 133.4 100.4 133.4 126 151.3 126 151.3 100.4 159 100.4 159 77.4 125.7 77.4 125.7 100.4"
								className="svg-elem-8" />
							<path
								d="M200.2,112.1c-1-3.4-2.4-6.5-4.1-9.2,1-1.2,1.9-2.5,2.5-4.1.7-1.9,1.1-4,1.1-6.3s-.5-5.2-1.6-7.4c-1.1-2.4-2.7-4.2-4.8-5.6-2.1-1.4-4.6-2.1-7.3-2.1h-24.1v48.6h17.9v-16.7c.7.4,1.5,1.1,2.1,2.8,1,2.5,1.6,6.7,1.9,12.6v1.3h18.6v-1.4c-.5-4.5-1.2-8.8-2.3-12.5Z"
								className="svg-elem-9" />
							<rect x="205.2" y="77.4" width="17.9" height="48.6" className="svg-elem-10" />
							<path
								d="M261.8,81.9c-.9-1-1.9-1.9-3-2.5-1.1-.7-2.3-1.2-3.6-1.5-1.2-.3-2.6-.5-3.9-.5h-22.5v48.6h17.9v-17.3c.7.5,1.4,1,2.1,1.3,1.4.7,2.9,1,4.5,1,2.3,0,4.5-.8,6.5-2.3,1.9-1.5,3.5-3.5,4.6-6.1,1.1-2.5,1.7-5.3,1.7-8.3s-.7-6.2-1.9-8.8c-.6-1.3-1.4-2.5-2.3-3.5Z"
								className="svg-elem-11" />


						</svg>
					</div>
				</div>
		)
}

export default SvgLogo;
