import clsx from 'clsx'
import SvgLogo from './SvgLogo'
import { useEffect, useState } from 'react'
import SvgMark from './SvgMark'

const Preloader =() => {
  const [hide, setHide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHide(true);
    }, 2000);
  }, [])

  return <div id="preloader" className={clsx(hide && 'slide-in')}>
        <div className="logos">
          <SvgLogo />
      </div>
    <SvgMark />

  </div>
}

export default Preloader;
