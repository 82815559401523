import { useEffect } from 'react'
import anime from 'animejs'

export default function SvgMark(){

  useEffect(() => {

    function init() {
      var timeline = anime.timeline({
        direction: 'alternate',
        easing: 'easeInOutQuint',
        autoplay: true,
        duration: function() {
          return anime.random(0, 270);
        },
        delay: [45, 250],
        loop:true,
      });
      timeline.add({
        targets: ['feDisplacementMap'],
        scale:[5 , 30, 10]
      });
      timeline.add({
        targets: ['#svg_8'],
        fill: ['#150485', '#000000', '#c62a88', '#03c4a1'],
        scale: [.5, 1.05],
        complete: init
      });

      anime({
        targets: ['#svg_12'],
        strokeDashoffset: [anime.setDashoffset, 0],
        points: [
          { value: [
              '88.5,80.45313l272.5,79.54688l-231,189l-41.5,-268.54688z',
              '207.5,185.45313l156.5,-26.45313l,190l-44.5,-268.54687z'], duration:20000,
          },
        ],
        fill: ['#150485', '#590995', '#c62a88', '#ffffff'],
        stroke: ['#dfc543', '#ad34fe', '#820036', '#00bdaa'],
        easing: 'easeOutQuad',
        duration: 2000,
        autoplay:true,
        loop: true,
      });
    }

    anime({
      targets: ['#svg_13 path' ],
      easing: ['easeOutInCirc'],
      strokeDashoffset:  [10, 0],
      duration: 1000,
      opacity:.5,
      skewY:100,
      skewX:100,
      rotate:[45, 90],
      autoplay:true,
      direction:'normal',
      loop: true,
      stroke: ['#150485', '#590995', '#c62a88', '#03c4a1'],
    });

    init();



  }, []);

  return (
    <svg id="hs" width="400" height="400" xmlns="http://www.w3.org/2000/svg">
    <defs>
    <filter id="glow">
      <fegaussianblur className="blur" result="coloredBlur" stddeviation="8"></fegaussianblur>
      <femerge>
        <femergenode in="coloredBlur"></femergenode>
        <femergenode in="coloredBlur"></femergenode>
        <femergenode in="coloredBlur"></femergenode>
        <femergenode in="SourceGraphic"></femergenode>
      </femerge>
    </filter>
    <filter id="filter-broken" filterUnits="objectBoundingBox" x="0" y="0" width="100%" height="100%"
            colorInterpolationFilters="sRGB">
      <feImage preserveAspectRatio="xMidYMid meet"
               xlinkHref="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'%3E%3Crect width='70%25' height='70%25' fill='white'/%3E%3Ccircle cx='50%25' cy='50%25' r='500' %0Afill='none' stroke='black' stroke-width='950' stroke-dasharray='200'/%3E%3C/svg%3E"
               result="lense" />
      <feDisplacementMap scale="10" xChannelSelector="B" yChannelSelector="G" in2="lense" in="SourceGraphic"
                         result="disMap" />
      <feMerge>
        <feMergeNode in="SourceGraphic" />
        <feMergeNode in="disMap" />
      </feMerge>
    </filter>
  </defs>
  <g>
    <path id="svg_12" className="path" d="m88.5,80.45313l272.5,79.54688l-231,189l-41.5,-268.54688z" strokeOpacity="null"
          strokeWidth="1.5" stroke="#000000" fill="none" />
    <g id="svg_8">
          <path id="svg_6" className="cls-3" d="M263.1,282.7s.7-1.1,1.9-3c-.4.3-.8.6-1.2,1-.4,1.3-.7,2-.7,2Z" />
           <path id="svg_7" className="cls-3"
                 d="M284.6,174.7c-1.8-17.4,1.7-32.9-5.1-45.1-9.8-17.5-53.5-25.1-62.3-45.9-.7-1.7-.7,4.4-1.1,9-.4,5.9-2.9,39.7,1.7,58,6.2,24.7,30.8,33.3,37.3,47.7,8.6,18.7.8,72.9,2.9,69,8.6-16.2,16.1-55.8,16.1-55.8,0,0,4,24.3.9,34.6-2.9,10-9.1,28.3-11.2,34.3.4-.3.8-.6,1.2-1,4.3-7.1,15-25.5,18.1-39.4,4-18.1,3.5-47.2,1.6-65.6ZM249.2,161.4c-11.1-1.1-20.5-9.5-20.5-9.5,0,0,6.5-5.9,11.2-7.8,0,0,0,0,0,0,0,0,0,0,.1,0,.2,0,.5-.2.7-.2,0,0,.2,0,.3,0,1.2-.3,2.5-.6,3.9-.7,4.1-.4,8.7-.1,12.6,1.2,5.2,1.8,15,10,15,10,2.2,2.3-11.4,8.3-23.4,7.1Z" />
          <path
            d="M154.6,205.3c1.5-5.7-3.7-9.8-1.7-17.7,1.1-4.6,12.6-6.7,17.5-11.9,6.9-7.3,20-19.3,24-32,4.7-15,4.2-58.2,4.2-58.2,0,0-8.5,10-13.8,13.8-5.9,4.2-14,8.3-21.5,11.2-9,3.5-23,3.6-32.4,9.6-9.4,6-19.8,16.3-24.2,26.5-4.4,10.2-1.8,23.2-2,34.8-.4,16.3-1.9,48.1-.1,63.2,1.1,9.7,10.5,27.4,10.5,27.4,0,0-4.5-18.1-4.8-26.4-.4-7.8,1.1-18.2,2.7-23.2,1.1-3.2,6.5-9.1,7.4-7.1.8,2-2.9,12.8-2.3,19.1.6,6.3,6.1,18.5,6.1,18.5,0,0-.3-21.5,1.9-27.6,1.6-4.5,11-9.3,11-9.3,0,0-5.1,12.4-6.2,18.6-1.1,6.1-1.7,13.3-.3,18.7,1.4,5.3,8.8,14,8.8,14,0,0-2.7-17.4-.6-25.6,2.6-10.3,13.7-27.4,16.2-36.4ZM124.5,148.4s7.1-8.1,18.6-10.6c0,0,0,0,0,0,0,0,0,0,0,0,.6-.1,1.2-.2,1.8-.3.1,0,.3,0,.4,0,1.2-.2,2.5-.3,3.8-.3.2,0,.3,0,.5,0,.7,0,1.3,0,2,0,.8,0,1.5.1,2.2.2.2,0,.4,0,.6,0,.5,0,1,.2,1.5.3.2,0,.4,0,.5.1,1.3.3,2.4.6,3.5,1.1,0,0,.2,0,.3.1.5.2.9.4,1.3.6,0,0,.2,0,.3.1.5.3,1,.5,1.5.8,0,0,0,0,0,0,4.8,3,7.7,7.7,12.1,12.4,0,0-16,7.2-26.9,5.6-12.9-1.9-23.9-10.1-23.9-10.1Z" />
          <path
            d="M197.9,243.1c-5.8-.3-11.7-2.1-17.4-.9-7.2,1.6-25.7,10.4-25.7,10.4,0,0,.5,20.4,2.7,20.5,1.3,0,.7-13.3,3.4-16.1,2.8-2.8,9-1.1,13.2-.9,4,.3,7.8,2.1,11.8,2.4,5.5.4,15.3.2,21.4,0,5,0,11.2-4,14.9-.7,3.6,3.3,7,20.3,7,20.3,0,0,.7-14.8,2-17.9.7-1.7,4.6-2.6,5.5-1,1.5,3.1,3.6,19.5,3.6,19.5,0,0,5.8-21.9,2.1-24.6-6.2-4.5-15.8-5.8-19.1-9.7-4.9-5.8-18.3-1-25.5-1.4Z" />
          <path d="M227.5,205.3c-.7-.7-2.9-1.3-4-.5-1.6,1-5.4,6.7-5.4,6.7,0,0,7.8-1.4,9.4-2.5,1-.7.7-3,0-3.7Z" />
          <path d="M183.3,208.4c1.2.9,15,1.6,15,1.6,0,0-4.8-4.9-7.3-5.2-2.5-.3-8.9,2.7-7.7,3.6Z" />
          <path className="cls-4" d="M145.3,137.4c1.2-.2,2.5-.3,3.8-.3-1.3,0-2.6.1-3.8.3Z" />
          <path d="M143.1,137.8s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0Z" />
          <path d="M163.4,140.5c-.5-.3-1-.6-1.5-.8.5.3,1,.5,1.5.8,0,0,0,0,0,0Z" />
          <path d="M156.5,137.8c1.3.3,2.4.6,3.5,1.1-1.1-.4-2.2-.8-3.5-1.1Z" />
          <path
            d="M152.6,155.1c6.3,0,11.4-5,11.4-11.1s-.2-2.4-.6-3.5c-.5-.3-1-.6-1.5-.8,0,0-.2,0-.3-.1-.4-.2-.9-.4-1.3-.6,0,0-.2,0-.3-.1-1.1-.4-2.2-.8-3.5-1.1-.2,0-.4,0-.5-.1-.5,0-1-.2-1.5-.3-.2,0-.4,0-.6,0-.7,0-1.4-.2-2.2-.2-.7,0-1.4,0-2,0-.2,0-.3,0-.5,0-1.3,0-2.6.1-3.8.3-.1,0-.3,0-.4,0-.6,0-1.2.2-1.8.3,0,0,0,0,0,0-1.2,1.8-1.9,3.9-1.9,6.2,0,6.1,5.1,11.1,11.4,11.1Z" />
          <path d="M151.7,137.1c.8,0,1.5.1,2.2.2-.7,0-1.4-.2-2.2-.2Z" />
          <path d="M245,143.1c-1.4.1-2.7.4-3.9.7,0,0-.2,0-.3,0,0,0,.2,0,.3,0,1.2-.3,2.5-.6,3.9-.7Z" />
          <path d="M240,144.2s0,0,0,0c0,0,.1,0,.1,0,0,0,0,0-.1,0Z" />
          <path
            d="M251,158.2c6.3,0,11.4-5,11.4-11.1v-.4c-1.8-1.1-3.4-2-4.8-2.5-3.9-1.3-8.5-1.6-12.6-1.2-1.4.1-2.7.4-3.9.7,0,0-.2,0-.3,0-.2,0-.5.1-.7.2,0,0-.1,0-.1,0-.3.9-.4,1.9-.4,3,0,6.1,5.1,11.1,11.4,11.1Z" />
        </g>



  </g>

  <path id="svg_13" className="path" d="m88.5,80.45313l272.5,79.54688l-231, 189l-41.5, -268.54688z" strokeOpacity="null"
        strokeWidth=".3" stroke="#e29e29" fill="none" transform="rotate(45)" />
  <animateMotion dur="5s" repeatCount="indefinite" rotate="auto-reverse">
    <mpath href="#svg_13" />
  </animateMotion>
</svg>
)
}
