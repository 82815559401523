import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import MyPreloader from "./components/common/PreloaderAnimejs";
const Top = lazy(() => import('./components/pages/Top'));

const AppRouter = () => {


  return (
       <Suspense fallback={ <MyPreloader show={false} /> }>
        <Router>
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="*" element={<Top />} />
          </Routes>
        </Router>
       </Suspense>
  );
};


export default AppRouter;
